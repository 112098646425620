<template>
    <v-row v-if="project">
        <v-col cols="12" md="10" offset-md="1">
            <v-card>
                <v-card-text v-show="issues > 0">
                    <issues-toolbar
                        :view.sync="view"
                        :group-displaying.sync="displayGroup"
                        v-on:groups-display="displayGroup = !displayGroup"
                        :issue-selected.sync="selected"
                        v-on:update-selected="clearSelected"
                    />
                    <issues-groups
                        v-if="displayGroup"
                        :view.sync="view"
                        :filters.sync="filters"
                        v-on:update-group="updateGroup"
                    />
                    <issues-view
                        ref="backlogView"
                        :view.sync="view"
                        :filters.sync="filters"
                        :group.sync="group"
                        :allow-select="true"
                        v-on:total-issues="updateTotalIssues"
                        v-on:update-selected="updateSelected"
                    />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issuesToolbar from '../../issues/issue/backlog-toolbar';
import issuesGroups from '../../issues/issue/groups';
import issuesView from '../../issues/issue/view';

export default {
    name: 'project-backlog',
    data: () => ({
        displayGroup: true,
        group: undefined,
        issues: 0,
        selected: [],
    }),
    components: { issuesToolbar, issuesGroups, issuesView },
    mounted() {
        this.findTeams();
    },
    computed: {
        ...mapGetters(['project']),
        view() {
            return this.project.views.find((v) => v.name === 'backlog');
        },
        filters() {
            return [
                {
                    field: 'sprint',
                    operator: '$emptyValue',
                    value: '',
                },
            ];
        },
    },
    methods: {
        ...mapActions(['findTeams']),
        updateGroup(group) {
            this.group = group;
        },
        updateTotalIssues(count) {
            this.issues = count;
        },
        updateSelected(selected) {
            this.selected = selected;
        },
        clearSelected() {
            this.$refs.backlogView.clearSelected();
            this.$refs.backlogView.updateView();
        },
    },
};
</script>

<style></style>
