<template>
    <v-toolbar dense flat class="mb-4" color="transparent">
        <sprint-selector
            :label="$t('issues.actions.available_sprints')"
            :project="project.name"
            v-model="sprintToAdd"
            class="mr-10"
            :init-active-sprint="true"
        />
        <v-btn
            v-show="sprintToAdd && issueSelected.length > 0"
            class="d-print-none"
            color="cyan darken-3"
            v-text="$t('buttons.addToSprint')"
            outlined
            small
            @click="addSprintToIssues"
        />
    </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import sprintSelector from '@/components/selectors/simple/sprint';

export default {
    name: 'project-backlog-toolbar',
    data: () => ({
        sprintToAdd: undefined,
    }),
    props: {
        view: {
            required: true,
        },
        groupDisplaying: {
            default: true,
        },
        issueSelected: {
            default: () => [],
        },
    },
    components: { sprintSelector },
    mounted() {},
    computed: {
        ...mapGetters(['project']),
        hasGroupToView() {
            return this.view.groupCol;
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        displayGroup() {
            this.$emit('groups-display');
        },
        async addSprintToIssues() {
            for (const issue of this.issueSelected) {
                await this.updateIssue({
                    id: issue.id,
                    sprint: this.sprintToAdd,
                });
            }

            this.$emit('update-selected');
        },
    },
};
</script>

<style></style>
